import React from 'react';
import HomeSection from '../../HomeSection';
import './Contact.css'

import {
    sectionTwoObj,
    sectionFourObj
} from './Data';




import '../Footer/Footer.css';
//import HubspotForm from 'react-hubspot-form'

const Contact = (  lightBg, topLine, lightText, lightTextDesc, headLine, description, buttonLabel, img, alt, imgStart, hideButton) => {
    return (
        <div>

              <HomeSection {...sectionTwoObj}/>
              <HomeSection {...sectionFourObj}/>
              
        </div>
    )
}

export default Contact;
