import React from 'react';
import HomeSection3 from '../../HomeSection3';
import './Terms.css'

import {
    sectionTwoObj,
} from './Data';



import '../Footer/Footer.css';


const Terms2 = (  lightBg, topLine, lightText, lightTextDesc, headLine, description, buttonLabel, img, alt, imgStart, hideButton) => {
    return (
        <div>

            <HomeSection3 {...sectionTwoObj}/>


            <div className={lightBg ? 'terms__home-section' : 'terms__home-section darkBg'}>

               <div className="container">

                    <p className = "terms">
                     <br/>
                     <br/>
                     <b> Privacy Policy </b>
                     <br/>
                     <br/>
                      Last Updated: 25-03-2023
                     <br/>
                     <br/>
                      Shenzu Spending, we, us, a company registered in India under The Companies Act 2013 [CIN U67100UP2018PTC107164] and in UK with Company Number [15334747]. At Shenzu Spending, we take your privacy seriously. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our services and when you use our website.
                       <br/>
                       <br/>

                     <b>Information We Collect </b>
                       <br/>
                       <br/>
                     We collect personal information from you when you use our Services, such as name, email address, any other additional pertinent information you provide to us so that we can offer you our products and IP address. We may also collect information about your device and browsing activities.
                     We might collect data directly from you or through other sources. We might get data from you when you:
                     <br/>
                     <br/>
                        •	go to our website;
                     <br/>
                     <br/>
                        •	fill out online forms with us;
                     <br/>
                     <br/>
                        •	participate in a phone conversation or online meeting with us; and
                     <br/>
                     <br/>
                        •	otherwise get in touch with us.
                       <br/>
                       <br/>
                          
                     <b>How We Use Your Information </b>
                       <br/>
                       <br/>
                     We use your information to provide and improve our products, services, send promotional communications, send marketing messages and enforce our Terms of Service and for the following purposes:
                      <br/>
                      <br/>
                        •	To provide and maintain our services.
                      <br/>
                      <br/>
                        •	To process transactions and payments.
                      <br/>
                      <br/>
                        •	To communicate with you, including responding to your inquiries and providing customer support.
                      <br/>
                      <br/>
                        •	To personalize your experience and improve our services.
                      <br/>
                      <br/>
                        •	To comply with legal obligations and enforce our policies.
                      <br/>
                      <br/>    
                      <b>Sharing Your Information: </b>
                       <br/>
                       <br/>

                     We may share your personal information with third-party service providers, such as delivery affiliate program service providers, customer service providers, advertising and marketing service providers, and distributors. We will only share your personal information in accordance with your consent and with contractual obligations to comply with applicable privacy standards. We may share your personal information with third parties in the following circumstances:
                       <br/>
                       <br/>
                        •	With service providers who assist us in operating our business and providing our services.
                       <br/>
                       <br/>
                        •	With financial institutions or payment processors to facilitate transactions.
                       <br/>
                       <br/>
                        •	When required by law or to protect our rights or the safety of others.
                       <br/>
                       <br/>
                         
                      <b>Security Safeguards </b>    
                       <br/>
                       <br/>
                      We are committed to ensuring that your personal information is secure. We have put in place reasonable physical, electronic, and managerial procedures to safeguard and secure the information we collect on our website and mobile applications. We classify your data based on importance and sensitivity, and ensure that your personal information has the highest security level.
                      <br/>
                      <br/>

                      <b>Regulatory Compliance</b>    
                       <br/>
                       <br/>
                       Shenzu Spending complies with UK financial regulations, leveraging Stripe, which is registered with FCA, for secure, compliant payment processing and Stellar for transparent, stable coin aligned cross-border transactions. Through these partnerships, we ensure that all personal and financial information is responsibly protected in line with applicable laws.
                       <br/>
                       <br/>

                      
                      <b>Cookies and Similar Technologies: </b>
                       <br/>
                       <br/>
                         We use cookies and similar technologies to personalize your experience and track usage patterns. You can manage your cookie preferences by adjusting your browser settings.
                       <br/>
                       <br/>
                            
                      <b>Your Rights: </b>
                       <br/>
                       <br/>
                      You have the right to access, correct, delete, restrict, or object to the processing of your personal information. You also have the right to withdraw your consent at any time.
                      <br/>
                      <br/>
                            
                      <b>Retention Period:</b>
                       <br/>
                       <br/>
                      We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
                       <br/>
                       <br/>
                            
                      <b> Changes to This Policy: </b>
                       
                       <br/>
                       <br/>
                      Shenzu Spending reserves the right, at its sole discretion, to alter its Services at any time, for any reason, and without notice or liability. We will not be liable for any unavailability of the Services as a consequence of planned or unforeseen system outages, or for any loss of Shenzu Spending Data or transactions as a result of the consequent delay. We reserve the right to update this Privacy Policy at any time. We will notify you of any material changes by posting a notice on our website or by sending you an email. We may need customization based on the specific practices and regulations applicable to Shenzu Spending. It's recommended to consult with country officials to ensure compliance with relevant laws, such as the General Data Protection Regulation (GDPR) if applicable. For any questions about these Terms of Service or any issues regarding Shenzu or its products please contact us via the Website.
    
                    </p>

               </div>

          </div>
        </div>
    )
}

export default Terms2;
