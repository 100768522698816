import React, { useState } from 'react';
import axios from 'axios';
import { FaMedium, FaLinkedin, FaGithub } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Button from '../../Button';
import './Footer.css';
import pg from 'pg';



const SubscriptionForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');


  const handleChange = (e) => {
    setEmail(e.target.value);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email) {
      try {

   //  const serverUrl = process.env.NODE_ENV === 'production' 
   // ? 'https://www.shenzuspending.com/api/subscribe' 
   // : 'http://localhost:5432/api/subscribe';
  //  const serverUrl = process.env.NODE_ENV === 'production'
  //  ? 'https://www.shenzuspending.com/api/subscribe'
  //  : process.env.NODE_ENV === 'development'
   //     ? 'http://localhost:5432/api/subscribe'
   //     : 'http://localhost:5432/api/subscribe'; // Default fallback URL
   //     const response = await axios.post('http://192.168.56.1:3000/api/subscribe', { email }); // Direct to production
   //     <Link to='https://getwaitlist.com/waitlist/20101' target='_blank' aria-label='Subscribe'></Link> 
   //      <SubscriptionForm />


   const serverUrl = process.env.REACT_APP_API_URL;

   const response = await axios.post(serverUrl, { email });
       // const response = await axios.post('http://localhost:5432/api/subscribe', { email });
        setMessage(response.data.message);
        setEmail('');
      } 
      
      catch (error) {
        console.error('Error adding subscription:', error);
        setMessage('Please try later');
      }

    } 
    
    else {
      setMessage('Please enter a valid email.');
    }
  };


  return (
    <form onSubmit={handleSubmit}>

      <input
        className='footer-input'
        name='email'
        type='email'
        value={email}
        onChange={handleChange}
        placeholder='Your Email'
        required
      />

      <Button buttonStyle='btn--outline'>Subscribe</Button>
      {message && <p>{message}</p>}
    </form>
  );
};

//Subscription form in the future


const Footer = () => {
  return (
    <div className='footer-container bg-black text-white'>

      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Subscribe to the Shenzu Community to stay up to date on the latest financial news and trends.
        </p>
        <div className='input-areas'>
        <a href="https://getwaitlist.com/waitlist/20101">
          <Button buttonStyle='btn--outline'>Subscribe</Button>
        </a>
        </div>
      </section>

      <div className="footer-links bg-black text-white">
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/privacy'>Privacy Policy</Link>
            <Link to='/'>Careers</Link>
            <Link to='/'>Legal</Link>
            <Link to='/terms'>Terms and Conditions</Link>
          </div>
    
          <div className='footer-link-items'>
            <h2>Videos</h2>
            <Link to='/'>Fintech</Link>
            <Link to='/'>Analytics</Link>
            <Link to='/'>Risk Management</Link>
          </div>
        </div>
    
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/contact'>Contact</Link>
            <Link to='/'>Sponsorships</Link>
            <Link to='/'>Support</Link>
          </div>
    
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='//github.com/' target='_blank' aria-label='Github'>
              Github
            </Link>
            <Link to='//linkedin.com/' target='_blank' aria-label='LinkedIn'>
              LinkedIn
            </Link>
            <Link to='//medium.com/' target='_blank' aria-label='Medium'>
              Medium
            </Link>
          </div>
    
        </div>
      </div>


      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              SHENZU SPENDING
            </Link>
          </div>
          <small className='website-rights'>SHENZU © 2024</small>
          <div className='social-icons'>
            <Link className='social-icon-link' to='//github.com/' target='_blank' aria-label='Github'>
              <FaGithub />
            </Link>

            <a
              className='social-icon-link'
              href='https://linkedin.com/company/shenzu-spending/'
              target='_blank'
              rel='noopener noreferrer' // Adding rel attribute for security
              aria-label='LinkedIn'
            >
              <FaLinkedin />
            </a>

            <Link className='social-icon-link' to='//medium.com/' target='_blank' aria-label='Medium'>
              <FaMedium />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}


export default Footer;
