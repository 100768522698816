const sectionThreeObj = {

    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Exclusive Access',
    headLine: 'Join our waitlist',
    description: 'To get access to our products join our waitlist, we are launching soon!',
    buttonLabel: 'Waitlist',
    imgStart: true,
    img: 'images/ShenzuSpending_6.png',
    alt: 'Credit Card'
}

const sectionOneObj = {

    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Join Us Today',
    headLine: 'Shenzu Spending!',
    description: 'Spending insights powered by AI and personalized financial guidance!',
    buttonLabel: 'Boss Do Giveaway',
    imgStart: true,
    img: 'images/Shenzu_42.png',
    alt: 'Credit Card'
}

const sectionTwoObj = {

    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Trust. Convenience. Reliability.',
    headLine: ' Fintech SaaS tool',
    description: 'AI-driven Cloud Automation, Data Extraction, Financial Credit Scoring Analytics',
    buttonLabel: 'Learn More',
    imgStart: false,
    img: 'images/Dashboard.png',
    alt: 'Credit Card'
}

const sectionFourObj = {

    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Safe. Secure.',
    headLine: 'Security is ensured with our edge systems.',
    description: 'We aim to safeguard your card and other finances.',
    buttonLabel: 'Learn more',
    imgStart: false,
    img: 'images/fintech6.jpg',
    alt: 'Credit Card'
}

export {sectionOneObj, sectionTwoObj, sectionThreeObj, sectionFourObj}
