import React from 'react';
import HomeSection3 from '../../HomeSection3';
import './Terms.css'

import {
    sectionTwoObj,
} from './Data';




import '../Footer/Footer.css';


const Terms = (  lightBg, topLine, lightText, lightTextDesc, headLine, description, buttonLabel, img, alt, imgStart, hideButton) => {
    return (
        <div>

            <HomeSection3 {...sectionTwoObj}/>


            <div className={lightBg ? 'terms__home-section' : 'terms__home-section darkBg'}>

               <div className="container">

                    <p className = "terms">
                      Shenzu Spending's Terms of Service (“Terms”) govern your access and use of this website (“Site”) and apply to all materials, software, services, material, online communications, and other information that is or becomes available on or through our Site (collectively, the “Material”). By using this site, you agree to be bound by these Terms and to use the Site only in accordance with these. We encourage your feedback, comments, and suggestions on how we might improve the Services (collectively, "Feedback").
                       <br/>
                       <br/>

                     <b>Information We Collect: </b>We collect personal information from you when you use our Services, such as name, email address, and IP address. We may also collect information about your device and browsing activities.

                       <br/>
                       <br/>
                      
                     <b>How We Use Your Information: </b>We use your information to provide and improve our Services, send promotional communications, and enforce our Terms of Service.

                       <br/>
                       <br/>

                      <b>Sharing Your Information: </b>We may share your information with third-party service providers, law enforcement agencies, or as required by law.
                       <br/>
                       <br/>

                      <b>Security Measures: </b>We maintain appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.
                       <br/>
                       <br/>

                      <b>Cookies and Similar Technologies: </b>We use cookies and similar technologies to personalize your experience and track usage patterns. You can manage your cookie preferences by adjusting your browser settings.
                       <br/>
                       <br/>

                      <b>Your Rights: </b>You have the right to access, correct, delete, restrict, or object to the processing of your personal information. You also have the right to withdraw your consent at any time.
                       <br/>
                       <br/>

                      <b> Retention Period: </b>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
                       <br/>
                       <br/>

                      <b> Links to Third-Party Sites: </b>Our Services may contain links to third-party sites, over which we have no control. We encourage you to read the privacy policies of each site before providing any personal information.
                       <br/>
                       <br/>

                      <b> Changes to This Policy: </b>We reserve the right to update this Privacy Policy at any time. We will notify you of any material changes by posting a notice on our website or by sending you an email.
                       
                       <br/>
                       <br/>


                      Please keep in mind that using certain Materials or conducting certain transactions on or through the Site may be subject to additional terms and conditions that will be made accessible to you prior to your use of such Material or participation in such transactions. You may not licence, sublicense, sell, resell, transfer, assign, or distribute the services, graphics on the site in any way other than as expressly permitted in writing by Shenzu Spending.
                       <br/>
                       <br/>

                      Shenzu Spending reserves the right, at its sole discretion, to alter its Services at any time, for any reason, and without notice or liability. We will not be liable for any unavailability of the Services as a consequence of planned or unforeseen system outages, or for any loss of Shenzu Spending Data or transactions as a result of the consequent delay.
                       <br/>
                       <br/>
    
                      For any questions about these Terms of Service or any issues regarding Shenzu or its products please contact us via the Website.
    
                    </p>

               </div>

          </div>
        </div>
    )
}

export default Terms;
