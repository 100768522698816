const sectionThreeObj = {

    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Exclusive Access',
    headLine: 'Unlimited Transcations with ZERO Fees',
    description: 'Get access to our exclusive card that allows you to send unlimited transactions without getting charged any fees',
    buttonLabel: 'Get Started Today',
    imgStart: true,
    img: 'images/svg-1.svg',
    alt: 'Credit Card'
}

const sectionOneObj = {

    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Join Us Today',
    headLine: 'Shenzu Spending!',
    description: 'Get access to our exclusive diamond card, which allows unlimited number of transactions without incurring any fees.',
    buttonLabel: 'Boss Do Giveaway',
    imgStart: true,
    img: 'images/svg-main.svg',
    alt: 'Credit Card'
}

const sectionTwoObj = {

    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Trust. Convenience. Reliability.',
    headLine: 'LOG IN',
    description: ' Trust. Convenience. Reliability.',
    buttonLabel: 'Log In',
    imgStart: false,
    img: 'images/svg-9.svg',
    alt: 'Credit Card',
    hideButton: true
}

const sectionFourObj = {

    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Safe. Secure.',
    headLine: 'With Our High-end Systems, Security is Guaranteed',
    description: 'Get access to our exclusive diamond card that allows you to send unlimited transactions without getting charged any fees',
    buttonLabel: 'Open a Shenzu Account',
    imgStart: false,
    img: 'images/svg-5.svg',
    alt: 'Credit Card'
}

export {sectionOneObj, sectionTwoObj, sectionThreeObj, sectionFourObj}
