const sectionThreeObj = {

    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Exclusive Access',
    headLine: 'Unlimited Transactions with ZERO Fees',
    description: 'Get access to our exclusive card, which allows you to send an unlimited number of transactions without incurring any fees.',
    buttonLabel: 'Get Started Today',
    imgStart: true,
    img: 'images/svg-1.svg',
    alt: 'Credit Card'
}

const sectionOneObj = {

    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Join Us Today',
    headLine: 'Shinzu Spending!',
    description: 'Instant Reload Bitcoin Debit Card with Unlimited Cash Withdrawal.',
    buttonLabel: 'Boss Do Giveaway',
    imgStart: true,
    img: 'images/svg-main.svg',
    alt: 'Credit Card'
}

const sectionTwoObj = {

    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headLine: 'TERMS OF SERVICE',
}




const sectionFourObj = {

    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Safe. Secure.',
    headLine: 'Security is ensured with our edge systems.',
    description: 'We will safeguard your card and other finances.',
    buttonLabel: 'Open a Shinzu Account',
    imgStart: false,
    img: 'images/svg-5.svg',
    alt: 'Credit Card'
}

export {sectionOneObj, sectionTwoObj, sectionThreeObj, sectionFourObj}
