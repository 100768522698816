import React from 'react';
import HomeSection from '../../HomeSection';
import HomeSection3 from '../../HomeSection3';
import HomeSection2 from '../../HomeSection2';
import {
    sectionOneObj,
    sectionTwoObj,
    sectionThreeObj,
    sectionFourObj,
} from './Data';
import Plans from '../../Plans';


const Home = () => {
    return (
        <div>
            <HomeSection3 {...sectionOneObj}/>
            <HomeSection {...sectionTwoObj}/>
            <HomeSection {...sectionThreeObj}/>
             {/* <Plans/> */}
            <HomeSection2 {...sectionFourObj}/>
        </div>
    )
}

export default Home;
