import React from 'react';
import HomeSection from '../../HomeSection';
import {
    sectionTwoObj,
} from './Data';
import Button from '../../Button';
import '../Footer/Footer.css';


const Products = () => {
    return (
        <div>
            <section className='footer-container footer-subscription log-in-section'>
                <p className='footer-subscription-heading'>
                Log In
                </p>
                <div className='input-areas'>
                <form>
                    <input
                    className='footer-input'
                    name='name'
                    type='text'
                    placeholder=''
                    />

                    <Button buttonStyle='btn--outline' >Log In</Button>
                </form>
                </div>
            </section>

            <HomeSection {...sectionTwoObj}/>
        </div>
    )
}

export default Products;
