import React from "react";
import { LogoWrapper, StyledLogo } from "./logostyle";
import {Link} from 'react-router-dom'
import logo from "./Shenzu Spending.png";

const Logo = () => {
  return (
    <Link to="">
      <LogoWrapper>
        <StyledLogo src={logo} />

      </LogoWrapper>
    </Link>
  );
};

export default Logo;
